'use client';

import { ReactNode } from 'react';

import { useUserStore } from '@utils/user';

function LoggedInOnly({ children }: { children: ReactNode }) {
  const { isLoggedIn } = useUserStore();
  if (!isLoggedIn) {
    return <></>;
  }

  return <>{children}</>;
}

export default LoggedInOnly;
