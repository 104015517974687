'use client';

import { ReactNode } from 'react';

import { useUserStore } from '@utils/user';

function FreemiumOnly({ children }: { children: ReactNode }) {
  const { isPremium } = useUserStore();
  if (isPremium) {
    return <></>;
  }

  return <>{children}</>;
}

export default FreemiumOnly;
