'use client';

import { ReactNode } from 'react';

import { useUserStore } from '@utils/user';

function PremiumOnly({ children }: { children: ReactNode }) {
  const { isPremium } = useUserStore();
  if (!isPremium) {
    return <></>;
  }

  return <>{children}</>;
}

export default PremiumOnly;
